$sprite: (
	logo: (
		offset-x: 0px,
		offset-y: 0px,
		width: 300px,
		height: 70px,
		total-width: 300px,
		total-height: 70px,
		image: '../img/sprite.png'
	),
);


@mixin sprite-size($image) {
	background-size: map-get(map-get($sprite, $image), 'total-width') map-get(map-get($sprite, $image), 'total-height');
}

@mixin sprite-width($image) {
	width: map-get(map-get($sprite, $image), 'width');
}

@mixin sprite-height($image) {
	height: map-get(map-get($sprite, $image), 'height');
}

@mixin sprite-position($image) {
	background-position: map-get(map-get($sprite, $image), 'offset-x') map-get(map-get($sprite, $image), 'offset-y');
}

@mixin sprite-image($image) {
	background-image: url(map-get(map-get($sprite, $image), 'image'));
}

@mixin sprite-image-retina($image) {
	$image-path: map-get(map-get($sprite, $image), 'image');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';

	background-image: url($image-path-retina);
}

@mixin sprite($image, $size: true) {
	@include sprite-image($image);
	@include sprite-position($image);
	background-repeat: no-repeat;

	@if $size {
		@include sprite-width($image);
		@include sprite-height($image);
	}

	@media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {

		@include sprite-size($image);
		@include sprite-image-retina($image);

	}
}



	.icon-logo {
		@include sprite(logo)
	}
