.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1; }
.owl-loaded {
  display: block;
  position: relative; }
.owl-loading {
  opacity: 0;
  display: block; }
.owl-hidden {
  opacity: 0; }
.o-refresh .owl-item {
  visibility: hidden; }
.o-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-grab {
  cursor: move;
  cursor: grab; }
.owl-rtl {
  direction: rtl;
  .owl-item {
    float: right; } }
.owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  &:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; } }
.owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px); }
.owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }
.owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  img {
    display: block;
    width: 100%; } }
.owl-nav.disabled, .owl-dots.disabled {
  display: none; }
.owl-nav {
  .owl-prev, .owl-next {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; } }
.owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }


.no-js .owl-carousel {
  display: block; }

.owl-carousel {
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both; }
  .owl-animated-in {
    z-index: 0; }
  .owl-animated-out {
    z-index: 1; }
  .fadeOut {
    animation-name: fadeOut; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }



.owl-height {
  transition: height 500ms ease-in-out; }


.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity 400ms ease; }
    img.owl-lazy {
      transform-style: preserve-3d; } }
  .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000; }
  .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease;
    &:hover {
      -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); } }
  .owl-video-playing {
    .owl-video-tn, .owl-video-play-icon {
      display: none; } }
  .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease; }
  .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%; } }

.owl-nav {
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between; }
//.owl-dots
//  text-align: center
//  -webkit-tap-highlight-color: transparent
//  .owl-dot
//    display: inline-block
//    zoom: 1
//    *display: inline
//    span
//      width: 10px
//      height: 10px
//      margin: 5px 7px
//      background: #D6D6D6
//      display: block
//      -webkit-backface-visibility: visible
//      transition: opacity 200ms ease
//      border-radius: 30px
//    &.active span, &:hover span
//      background: #869791

.owl-dots {
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  .owl-dot {
    padding: 0;
    font-size: 0;
    width: 37px;
    height: 4px;
    background-color: #E6E6E6;
    margin-left: 6px;
    margin-right: 6px;
    transition: all $transitSpeed $ease;
    &.active {
      background-color: #000; } } }



.owl-prev,
.owl-next {
  display: block;
  border-radius: 50%;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-color: transparent;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% auto;
  width: 60px;
  height: 60px;
  padding: 0;
  border: none;
  transition: all $transitSpeed $ease;
  &:hover {
    transform: translateY(-50%) scale(1.05); } }



.owl-prev {
  background-image: url(../../img/arrow-left.svg);
  left: 25px; }


.owl-next {
  background-image: url(../../img/arrow-right.svg);
  right: 25px; }
