.resta-body {
  background-color: #fafafa;
  padding-top: 100px;
  .header__menu {
    background-image: url(../img/menu-icon-b.png); } }

.resta {
  &__title {
    margin-top: 0;
    margin-bottom: 100px;
    text-transform: uppercase;
    text-align: center;
    font-size: 38px;
    font-family: $futura;
    font-weight: 400; }
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding-bottom: 30px;
    background-color: #fff;
    &:last-child {
      padding-bottom: 80px; } }
  &__img {
    width: 60%;
    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover; } }
  &__info {
    width: 40%;
    padding: 50px 100px; }
  &__subtitle {
    font-size: 30px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    font-family: $futura;
    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url(../img/pseudo-title.png);
      width: 45px;
      height: 13px; } }
  &__bold {
    margin-top: 20px;
    margin-bottom: 5px; }
  &__text {
    margin: 0;
    &--last {
      margin-top: 20px;
      margin-bottom: 10px; } }
  &__contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
  &__icon {
    display: inline-block;
    position: relative;
    text-align: center;
    padding-top: 50px;
    min-width: 55px;
    text-transform: uppercase;
    font-size: 11px;
    margin-right: 15px;
    color: #d45055;
    &:hover:before {
      transform: translateX(-50%) scale(1.05); }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 40px;
      background-color: #d45055;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 50% 50%;
      transition: all $transitSpeed; }
    &--tel:before {
      background-image: url(../img/telephone.svg); }
    &--map:before {
      background-image: url(../img/map.svg); }
    &--mail:before {
      background-image: url(../img/envelope.svg); }
    &:last-child {
      margin-right: 0; } } }

@include r($wide) {
  .resta {
    &__info {
      padding: 35px 50px; } } }
@include r($desktop) {
  .resta {
    &__title {
      margin-bottom: 60px; }
    &__info {
      width: 50%;
      padding: 25px 30px; }
    &__img {
      width: 50%; }
    &__subtitle {
      font-size: 24px;
      margin-bottom: 15px; } } }

@include r($tablet) {
  .resta {
    &__title {
      font-size: 32px;
      margin-bottom: 40px; }
    &__wrap {
      flex-direction: column;
      &--rotate {
        .resta__img {
          order: 0; }
        .resta__info {
          order: 1; } } }
    &__info {
      width: 100%; }
    &__img {
      width: 100%; } } }
