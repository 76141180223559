.footer {
  padding-top: 60px;
  padding-bottom: 20px;
  text-align: center;
  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  &__link {
    text-transform: uppercase;
    margin: 0 15px;
    font-size: 12px;
    font-family: $futura;
    &:hover {
      text-decoration: underline; } }
  &__socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 5px; }
  &__fb,
  &__inst {
    margin: 0 3px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    transition: all $transitSpeed;
    background-size: 100% 100%;
    &:hover {
      transform: scale(1.05); } }
  &__fb {
    background-image: url(../img/fb-icon.png); }
  &__inst {
    background-image: url(../img/inst-icon.png); }
  &__hashtag {
    text-transform: uppercase;
    text-align: center;
    font-size: 13px;
    font-family: $futura; } }

@include r($tablet) {
  .footer {
    padding-top: 40px; } }

@include r($mobile) {
  .footer {
    &__link {
      margin: 0 7px; } } }
