.carta {
  padding-bottom: 80px;
  background-image: linear-gradient(to bottom, #fafafa, #fff);
  &__title {
    margin-top: 0;
    margin-bottom: 100px;
    text-transform: uppercase;
    text-align: center;
    font-size: 38px;
    font-family: $futura;
    font-weight: 400; }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: calc(33.33% - 14px);
    background-color: #fff;
    margin-left: 7px;
    margin-right: 7px;
    padding: 20px; }
  &__btn {
    text-align: center;
    padding: 20px 45px;
    text-transform: uppercase;
    font-size: 12px;
    color: #fff;
    background-color: #d45055;
    display: inline-block;
    margin-bottom: 10px;
    transition: all $transitSpeed $ease;
    &:hover {
      transform: scale(1.05);
      opacity: 0.8; } }
  &__text {
    font-size: 16px;
    margin: 0;
    max-width: 175px; } }


.carta-item {
  &__container {
    background: linear-gradient(to bottom, #fafafa 15%, #fff 15%, #fff 100%); }
  &__slider {
    max-width: 700px;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    &-wrap {
      max-width: 460px;
      margin-left: auto;
      padding: 15px 30px 20px 30px;
      background-color: #fff;
      margin-right: auto; }
    h3 {
      margin-top: 0;
      margin-bottom: 10px; }
    .owl-nav {
      top: 27%;
      transform: none; }
    .owl-prev,
    .owl-next {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #d45055;
      background-size: 80%; }
    .owl-prev {
      background-image: url(../img/left-arrow.svg);
      left: 95px; }
    .owl-next {
      background-image: url(../img/right-arrow.svg);
      right: 95px; } }
  &__title {
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 30px;
    font-family: $futura;
    font-weight: 400; }
  &__descr {
    padding-left: 30px;
    padding-right: 30px; }
  &__text {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto; }
  &__span {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-bottom: 30px; }
  .carta__item {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 50px; }
  &__gallery {
    padding: 20px 40px 50px 40px;
    .owl-dots {
      bottom: 30px; }
    .owl-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-left: 4px;
      margin-right: 4px;
      &.active {
        background-color: #d45055; } } } }

@include r($desktop) {
  .carta {
    &__title {
      margin-bottom: 60px; } } }

@include r($tablet) {
  .carta {
    &__title {
      font-size: 32px;
      margin-bottom: 40px; }
    &__item {
      width: calc(50% - 14px); } }
  .carta-item {
    &__title {
      font-size: 26px; } } }

@include r($mobile) {
  .carta {
    &__item {
      width: 100%;
      margin-left: 0;
      margin-right: 0; } }
  .carta-item {
    &__slider {
      .owl-nav {
        top: 23%; }
      .owl-prev,
      .owl-next {
        width: 30px;
        height: 30px; }
      .owl-prev {
        left: 5px; }
      .owl-next {
        right: 5px; } }
    &__slider-item {
      padding: 15px 45px 20px 45px; }
    &__title {
      font-size: 22px; } } }

@include r($mobile-s) {
  .carta-item {
    &__slider {
      .owl-nav {
        top: 17%; } } } }
