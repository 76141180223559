.historia {
  background-color: #fff;
  padding: 50px 0;
  .promo__down {
    display: block;
    max-width: 50px;
    margin-top: 50px;
    position: relative;
    transform: none;
    top: 0;
    left: 0;
    color: #000;
    margin-left: auto;
    margin-right: auto;
    &:after {
      background-image: url(../img/arrow-down-b.png); } }
  .resta {
    &__wrap {
      margin-bottom: 30px;
      align-items: center; }
    &__img {
      width: 50%;
      img {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto; } }
    &__info {
      padding: 50px 70px;
      width: 50%; }
    &__subtitle {
      font-weight: 400;
      span {
        display: block;
        font-size: 16px;
        color: #777777;
        text-transform: none;
        font-family: 'Playfair Display', serif; } }
    &__text {
      font-size: 16px;
      margin-bottom: 20px; } }
  &__subtitle {
    padding-bottom: 0;
    &:after {
      display: none; } }
  &__wrap {
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto; } }

@include r($tablet) {
  .historia {
    .resta {
      &__img {
        width: 100%;
        img {
          max-width: 70%; } }
      &__info {
        width: 100%;
        padding: 25px 0px;
        max-width: 70%;
        margin-left: auto;
        margin-right: auto; } } } }

@include r($mobile) {
  .historia {
    .resta {
      &__wrap {
        margin-bottom: 0; }
      &__img {
        img {
          max-width: 100%; } }
      &__info {
        max-width: 100%; } } } }
