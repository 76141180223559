// LAYOUT
$mobile-s: 375;
$mobile: 568;
$tablet: 767;
$desktop: 992;
$wide: 1336;
$hd: 1440;

// COLORS
$mainColor: #005062;
$mainColor2: #0EB4C4;

// OPTIONS
$transitSpeed: .25s;
$ease: ease-in-out;

// SOCIALS
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;


$futura: 'Futura PT';
