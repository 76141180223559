.main-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255,255,255);
  z-index: 10;
  padding-bottom: 30px;
  visibility: hidden;
  opacity: 0;
  transition: all $transitSpeed 0.3s;
  &.is-active {
    visibility: visible;
    opacity: 1; }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 90px;
    padding-top: 30px; }
  &__logo {
    display: block;
    height: auto;
    margin-right: auto;
    width: 95px;
    height: 40px; }
  &__lang {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80px;
    margin-right: 35px;
    span {
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 3px;
      font-family: $futura; }
    a {
      width: 26px;
      height: 17px;
      margin: 0 2px;
      background-repeat: no-repeat;
      background-size: 100% 100%; } }
  &__close {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 0;
    background-color: transparent;
    padding: 0;
    background-image: url(../img/close-icon.png);
    width: 25px;
    height: 25px;
    transition: all $transitSpeed $ease;
    &:hover {
      cursor: pointer;
      opacity: 0.7; } }

  &__eng {
    background-image: url(../img/eng-flag.png); }
  &__port {
    background-image: url(../img/port-flag.png); }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto; }
  &__info {
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  &__imgs {
    position: relative;
    width: 48%;
    img {
      display: block;
      width: 100%;
      height: auto; } }
  &__img {
    &--first {
      position: relative;
      top: 20px; }
    &--second {
      position: absolute;
      left: 50%;
      top: 33%;
      z-index: 2; }
    &--third {
      position: absolute;
      left: 100%;
      top: 22%; } }
  &__title {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: $futura; }
  &__text {
    font-size: 17px;
    margin: 0;
    color: #c2c2c2;
    max-width: 250px;
    font-weight: 500;
    font-family: $futura; }
  &__descr {
    width: 48%; }
  &__menu {
    width: 45%;
    text-align: right; }
  &__link {
    display: table;
    margin-left: auto;
    text-align: right;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 30px;
    font-family: $futura;
    color: #5e5e5e;
    transition: all $transitSpeed $ease;
    &.is-active,
    &:hover {
      color: #000; } }
  &__mobile-wrap {
    display: none; }
  &__socials {
    margin-top: 90px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center; }
  &__fb,
  &__inst {
    margin: 0 3px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    transition: all $transitSpeed;
    background-size: 100% 100%;
    &:hover {
      transform: scale(1.05); } }
  &__fb {
    background-image: url(../img/fb-icon.png); }
  &__inst {
    background-image: url(../img/inst-icon.png); }
  &__hashtag {
    font-size: 12px;
    text-transform: uppercase;
    font-family: $futura; }
  &__copy {
    font-size: 12px;
    color: #c2c2c2;
    margin-top: 5px;
    margin-bottom: 0;
    font-family: $futura; } }

@include r($desktop) {
  .main-nav {
    &__header {
      margin-bottom: 70px; }
    &__info {
      width: 65%; }
    &__imgs {
      width: 45%; }
    &__descr {
      width: 52%; }
    &__menu {
      width: 35%; }
    &__link {
      font-size: 20px;
      margin-top: 20px; }
    &__img {
      &--second {
        top: 40%; }
      &--third {
        top: 25%; } } } }

@include r($tablet) {
  .main-nav {
    overflow-y: scroll;
    &__wrap {
      flex-direction: column; }
    &__header {
      margin-bottom: 30px; }
    &__menu {
      order: 0;
      width: 100%;
      text-align: center;
      margin-bottom: 40px; }
    &__link {
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
    &__info {
      order: 1;
      width: 90%;
      margin-left: auto;
      margin-right: auto; }
    &__desktop-wrap {
      display: none; }
    &__mobile-wrap {
      display: block;
      order: 2;
      width: 100%;
      text-align: center;
      margin-top: 100px; }
    &__socials {
      margin-top: 10px;
      text-align: center;
      justify-content: center; } } }

@include r($mobile) {
  .main-nav {
    &__header {
      padding-top: 20px; }
    &__info {
      width: 100%; }
    &__title {
      font-size: 16px;
      margin-bottom: 5px; }
    &__text {
      font-size: 14px; }
    &__img {
      &--second {
        top: 45%; }
      &--third {
        top: 35%; } } } }
