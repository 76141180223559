.wow {
  visibility: hidden;
  @include r($desktop) {
    visibility: visible; } }

.wowFadeUp, .wowFadeDown, .wowFadeLeft, .wowFadeRight {
  opacity: 0;
  // added with scrollmonitor
  // animation-name: wowFade;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  @include r($desktop) {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.wowFadeUp {
  transform: translate3d(0, 30px, 0); }

.wowFadeDown {
  transform: translate3d(0, -30px, 0); }

.wowFadeLeft {
  transform: translate3d(-30px, 0px, 0); }

.wowFadeRight {
  transform: translate3d(30px, 0px, 0); }

@keyframes wowFade {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }
