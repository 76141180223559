.header {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 35px;
  z-index: 3;
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }
  &__menu {
    padding: 0;
    background-color: transparent;
    border: 0;
    width: 32px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../img/menu-icon.png);
    transition: all $transitSpeed;
    &:hover {
      cursor: pointer;
      opacity: 0.7; } } }


@include r($mobile) {
  .header {
    padding-top: 20px; } }
