.promo {
  background-color: #070405;
  position: relative;
  overflow: hidden;
  &__slider {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
      min-height: 100%; }
    .owl-dots {
      z-index: 3;
      bottom: 80px; }
    .owl-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-left: 4px;
      margin-right: 4px;
      &.active {
        background-color: #d45055; } } }
  &__slide {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    &--first {
      background-image: url(../img/promo-bg.jpg); }
    &--second {
      background-image: url(../img/promo-bg.jpg); } }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -33px;
    width: 105%;
    height: 75px;
    background-color: #fff;
    transform: rotate(-2deg); }

  .container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative; }
  &__title {
    color: #fff;
    font-size: 38px;
    text-transform: uppercase;
    max-width: 470px;
    font-weight: 400;
    font-family: $futura;
    margin: 0; }
  &__subtitle {
    font-size: 17px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 0; }

  &__down {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    padding-bottom: 10px;
    position: absolute;
    bottom: 150px;
    right: 50px;
    font-family: $futura;
    &:hover {
      &:after {
        transform: translate(-50%, 6px); } }
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transition: all $transitSpeed;
      transform: translate(-50%, 0px);
      bottom: -13px;
      background-repeat: no-repeat;
      background-image: url(../img/arrow-down.png);
      background-size: 100%;
      width: 28px;
      height: 16px; } } }

.about {
  padding-top: 50px;
  padding-bottom: 85px;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -33px;
    width: 105%;
    height: 75px;
    background-color: #fafafa;
    transform: rotate(-2deg); }
  &__text {
    font-size: 17px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 15px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      transform: translateX(-50%);
      top: -25px;
      left: 50%;
      background-repeat: no-repeat;
      background-image: url(../img/tomato-icon.png);
      background-size: 100% 100%;
      width: 20px;
      height: 15px; } }
  &__more {
    display: block;
    max-width: 74px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../img/see-more.png); } }

.our-menu {
  background-color: #fafafa;
  background-image: linear-gradient(to bottom, #fafafa, #fff);
  padding-top: 50px;
  padding-bottom: 30px;
  &__title {
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 400;
    font-family: $futura; }
  &__wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px; }
  &__item {
    width: calc(33.33% - 14px);
    margin-left: 7px;
    margin-right: 7px;
    padding: 40px 40px 0 40px;
    background-color: #fff;
    &:hover {
      .our-menu__item-img:before {
        visibility: visible;
        opacity: 1; }
      .our-menu__item-explore {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, -50%); } }
    &-img {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.4s;
        background-color: rgba(255,255,255,0.8);
        visibility: hidden;
        opacity: 0; }
      img {
        display: block;
        width: 100%;
        height: auto; } }
    &-title {
      font-size: 21px;
      margin-top: 20px;
      margin-bottom: 0;
      padding-bottom: 25px;
      position: relative;
      font-family: $futura;
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      &:after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../img/pseudo-title.png);
        width: 45px;
        height: 13px; } }

    &-descr {
      margin: 0;
      font-size: 17px;
      text-align: center; }
    &-explore {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all $transitSpeed;
      transform: translate(-50%, -15%);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url(../img/explore.png);
      width: 64px;
      height: 15px;
      visibility: hidden;
      opacity: 0; } }
  &__more {
    display: block;
    max-width: 74px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 100%;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../img/see-more.png); } }

.kitchen {
  padding-top: 100px;
  padding-bottom: 100px;
  &__wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  &__descr {
    width: 50%; }
  &__imgs {
    width: 50%;
    position: relative; }
  &__img {
    display: block;
    height: auto;
    width: 50%;
    &--second {
      position: absolute;
      left: 20%;
      top: -10%; }
    &--third {
      position: absolute;
      right: 0;
      bottom: -10%; } }
  &__title {
    font-size: 22px;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: $futura; }
  &__text {
    font-size: 17px;
    margin: 0;
    max-width: 420px; } }

.location {
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-image: url(../img/map-bg.jpg);
  background-size: cover;
  .container {
    min-height: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  &__title {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    font-family: $futura;
    font-weight: 400; }
  &__text {
    font-size: 17px;
    text-align: center;
    max-width: 460px;
    margin: 0 auto; }
  &__more {
    display: block;
    max-width: 74px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 100%;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../img/see-more.png); } }

@include r($wide) {
  .our-menu {
    &__item {
      padding: 30px 30px 0 30px; } } }

@include r($desktop) {
  .promo {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.3); }
    .container {
      min-height: 80vh; }
    &__down {
      right: 30px; } }
  .about {
    padding-top: 35px;
    padding-bottom: 70px; }
  .our-menu {
    padding-top: 35px;
    &__title {
      font-size: 27px; }
    &__item {
      padding: 20px 20px 0 20px;
      &-title {
        font-size: 18px;
        margin-top: 18px;
        &:after {
          width: 40px;
          height: 10px; } }
      &-descr {
        font-size: 15px; } } }
  .kitchen {
    padding: 80px 0;
    &__descr {
      padding-right: 20px; } }
  .location {
    .container {
      min-height: 250px; } } }

@include r($tablet) {
  .promo {
    &__title {
      font-size: 32px; }
    &__down {
      bottom: 100px; } }
  .our-menu {
    &__item {
      width: calc(50% - 14px); } }
  .kitchen {
    padding-top: 40px;
    &__wrap {
      flex-direction: column;
      justify-content: center; }
    &__descr {
      padding-right: 0;
      width: 100%;
      text-align: center; }
    &__text {
      margin-left: auto;
      margin-right: auto; }
    &__imgs {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 55px; } } }

@include r($mobile) {
  .promo {
    .container {
      min-height: 70vh; }
    &__title {
      font-size: 28px; }
    &__down {
      bottom: 80px; } }
  .our-menu {
    &__wrap {
      margin-left: 0;
      margin-right: 0; }
    &__item {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 10px; } } }
