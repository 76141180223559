* {
  box-sizing: border-box;
  outline: none; }

a {
  color: inherit;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none; } }

ul {
  list-style: none;
  li {
    display: inline-block; } }

body, html {
  background: white;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  min-width: 320px;
  max-width: 100%;
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
  &.no-scroll {
    overflow: hidden;
    max-height: 100vh; } }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__content {
    // used for sticky footer (flexbox way)
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
 } }    // header offset ex

.container {
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px; }

@include r($desktop) {
  .container {
    padding-left: 30px;
    padding-right: 30px; } }


// REMOVE FOR PRODUCTION !
.dev-bp-debug {
  background: black;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  color: white;
  padding: 5px;
  font-size: 12px;
  border-bottom-left-radius: 5px; }


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }
