.ico-arrow-btn {
  width: 1.4em;
  height: 1em;
  fill: #005062;
}
.ico-arrow-left {
  width: 0.55em;
  height: 1em;
  fill: #FFF;
}
.ico-arrow-right {
  width: 0.55em;
  height: 1em;
  fill: #FFF;
}
.ico-close {
  width: 0.98em;
  height: 1em;
  fill: #005062;
}
.ico-compas {
  width: 0.97em;
  height: 1em;
  fill: #063B46;
}
.ico-docs {
  width: 1em;
  height: 1em;
  fill: #005062;
}
.ico-eye {
  width: 1.33em;
  height: 1em;
  fill: #FFF;
}
.ico-flat {
  width: 4.06em;
  height: 1em;
  fill: #FFF;
}
.ico-house {
  width: 1.06em;
  height: 1em;
  fill: #FFF;
}
.ico-map-white {
  width: 0.7em;
  height: 1em;
  fill: #FFF;
}
.ico-menu {
  width: 1.5em;
  height: 1em;
  fill: #005062;
}
.ico-play-big {
  width: 1em;
  height: 1em;
  fill: #FFF;
}
.ico-play-mini {
  width: 1em;
  height: 1em;
  fill: #FFF;
}
.ico-ruble {
  width: 0.79em;
  height: 1em;
  fill: #0EB4C4;
}
.ico-sales {
  width: 0.96em;
  height: 1em;
  fill: #FFF;
}
.ico-wave-left {
  width: 2.55em;
  height: 1em;
  fill: #0EB4C4;
}
.ico-wave-right {
  width: 2.55em;
  height: 1em;
  fill: #0EB4C4;
}
